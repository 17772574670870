export default function toPlainText(blocks = []) {
  return blocks
    .map((block) => {
      switch (block._type) {
        case "block":
          return block.children.map((child) => child.text).join("");
        case "code":
          return block.code;
        case "span":
          return block.text;
        default:
          return "";
      }
    })
    .join("\n\n");
}
