import excerpt from "./excerpt";
import { Link } from "gatsby";
import React from "react";

export default function Post({ post }) {
  return (
    <div
      key={post._id}
      className="bg-white border p-8 shadow-md rounded-lg mb-5"
    >
      <h3 className="text-gray-800 text-2xl mb-3">{post.title}</h3>
      <p className="mb-2">{excerpt(post)}</p>
      <Link className="text-brand-blue" to={`/posts/${post.slug.current}`}>
        Read more &#8594;
      </Link>
    </div>
  );
}
