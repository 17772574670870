import Container from "../ui/Container";
import Post from "./Post";
import React from "react";
import DefaultLayout from "../ui/DefaultLayout";
import Helmet from "react-helmet";

export default function PostsPage({ pageContext }) {
  const { posts } = pageContext;

  return (
    <DefaultLayout>
      <Helmet>
        <title>Blog posts</title>
        <meta
          name="description"
          content="Fun tutorials and articles all things programming."
        />
      </Helmet>

      <div className="bg-gray-100 flex-col flex flex-grow">
        <Container>
          {posts
            .sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt))
            .map((post) => (
              <Post key={post.slug.current} post={post} />
            ))}
        </Container>
      </div>
    </DefaultLayout>
  );
}
